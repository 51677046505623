/**
 * Based on code by Rick Strahl
 * From https://weblog.west-wind.com/posts/2015/feb/26/using-fontawesome-fonts-for-html-radio-buttons-and-checkboxes
 */

@mixin ngx-radio-checkboxes-theme() {
  input[type=radio].with-font,
  input[type=checkbox].with-font {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    position: relative;
    top: 5px;
  }

  input[type=radio].with-font ~ label::before,
  input[type=checkbox].with-font ~ label::before {
    font-family: 'Font Awesome\ 5 Free', serif;
    display: inline-block;
    content: '\f111'; /* fa-circle-thin */
    letter-spacing: 10px;
    font-size: 1.2em;
    font-weight: 400; /* Regular style */
    //color: nb-theme(color-primary-500);
    width: 1.4em; /* reduce bounce */
  }

  input[type=radio].with-font:checked ~ label::before,
  input[type=checkbox].with-font:checked ~ label::before {
    content: '\f00c'; /* fa-check */
    font-size: 1.2em;
    font-weight: 900;
    //color: nb-theme(color-success-500);
    letter-spacing: 5px;
  }

  input[type=checkbox].with-font ~ label::before {
    content: '\f0c8'; /* fa-square */
  }

  input[type=checkbox].with-font:checked ~ label::before {
    content: '\f14a'; /* fa-check-square */
    //color: nb-theme(color-success-500);
  }

  //input[type=radio].with-font:focus ~ label::before,
  //input[type=checkbox].with-font:focus ~ label::before,
  //input[type=radio].with-font:focus ~ label,
  //input[type=checkbox].with-font:focus ~ label {
  //  color: nb-theme(color-success-300); /* highlight both box and label */
  //}
}
